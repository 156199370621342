import api from '../../api/ramses'

async function createUser({ user_id, full_access }) {
  try {
    return await api.post('/osiris/user', {
      user_id,
      full_access,
    })
  } catch ({ response }) {
    return response
  }
}

export {
    createUser as createOsirisUser,
}
