import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL

const token = sessionStorage.getItem('token')
axios.defaults.headers.common.Authorization = `Bearer ${token}`

async function createProfile({ profileName, profilePermissions }) {
  try {
    return await axios.post(`${BASE_URL}/backoffice/profile`, {
      profile_name: profileName,
      permissions: profilePermissions,
    })
  } catch ({ response }) {
    return response
  }
}

async function getProfile(id) {
  try {
    return await axios.get(`${BASE_URL}/backoffice/profile/${id}`)
  } catch ({ response }) {
    return response
  }
}

async function listProfile() {
  try {
    return await axios.get(`${BASE_URL}/backoffice/profile`)
  } catch ({ response }) {
    return response
  }
}

async function updateProfile({ id, profileName, permissions }) {
  try {
    return await axios.put(`${BASE_URL}/backoffice/profile/${id}`, {
      profile_name: profileName,
      permissions,
    }).then(response => response.status)
  } catch ({ response }) {
    return response
  }
}

export {
  createProfile,
  getProfile,
  listProfile,
  updateProfile,
}
