import { ramses } from '../api'

class PermissionService {
    api = ramses

    async index() {
      try {
        return await this.api.get('permission')
      } catch ({ response }) {
        return response
      }
    }
}

export default new PermissionService()
