<template>
  <b-container
    v-if="renderPage"
    fluid
  >
    <b-tabs
      content-class="mt-2"
      justified
      align="center"
    >
      <b-tab
        title="Usuários"
        lazy
      >
        <list-all-user />
      </b-tab>
      <b-tab
        title="Perfil"
        lazy
      >
        <list-profile />
      </b-tab>

      <b-tab
        title="Perfil do Whatsapp"
        lazy
      >
        <ListProfileWhatsapp />
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import {
    BContainer,
    BTab,
    BTabs,
} from 'bootstrap-vue';

import NewProfile from './NewProfile.vue';
import NewUserBackOffice from './newUserBackOffice.vue';
import ListAllUser from './ListAllUsers.vue';
import ListProfile from './ListProfile.vue';
import ListProfileWhatsapp from './ListProfileWhatsapp.vue';

export default {
    components: {
        ListProfileWhatsapp,
        NewProfile,
        NewUserBackOffice,
        ListAllUser,
        ListProfile,
        BTabs,
        BTab,
        BContainer,
    },
    data() {
        return ({
            renderPage: true,
        });
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/user.scss";
</style>
