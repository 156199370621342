<template>
  <div v-if="optionsPermissions.length !== 0">
    <label v-if="optionsPermissions.length > 1">
      {{ item }}
    </label>

    <div :class="optionsPermissions.length > 1 ? 'ml-1 mt-1' : ''">
      <b-form-checkbox-group
        v-if="selected || optionsPermissions.length === 1"
        v-model="selecteds"
        inline
        stacked
        :options="optionsPermissions"
        style="column-count: 2"
        switches
        @change="updatePermission"
      />
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormCheckboxGroup, BButton } from 'bootstrap-vue';

export default {
    components: {
        BButton,
        BFormCheckbox,
        BFormCheckboxGroup,
    },
    props: {
        item: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            default: () => [],
        },

        permissions: {
            type: Array,
            default: () => [],
        },

        new: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            selected: true,
            selecteds: this.permissions,
            notShowPermissions: [
                2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 45, 56, 57,
            ],
        };
    },

    computed: {
        optionsPermissions() {
            return this.options.filter(item => !this.notShowPermissions.includes(item.id)).map(item => ({ text: item.permission_in_portuguese, value: item.id }));
        },
    },

    watch: {

        permissions() {
            this.selecteds = this.permissions.filter(valor => this.optionsPermissions.find(item => item.value === valor));
        },

        selected(value) {
            if (!value) {
                this.selecteds = [];
            }
            this.updatePermission();
        },
    },

    methods: {
        updatePermission() {
            this.$emit('update', {
                calculated: this.selecteds,
                all: this.optionsPermissions.map(item => item.value),
            });
        },

    },
};
</script>
