<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="showLoading"
      rounded="lg"
      variant="white"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Cadastrando Usuário, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-card title="Cadastro de Usuário">
        <validation-observer ref="simpleRules">
          <b-form
            novalidate
            class="needs-validation"
            @submit.prevent
          >
            <b-row>
              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-name">Nome: </label>
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required"
                >
                  <b-form-input
                    id="v-name"
                    v-model="userName"
                    placeholder="Digite o nome do usuário"
                    :state="
                      errors.length > 0 ? false : null
                    "
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <label for="v-email">Email do Usuário </label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="v-email"
                    v-model="userEmail"
                    placeholder="Digite o email do usuário"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-setor">Setor: </label>
                <validation-provider
                  #default="{ errors }"
                  name="setor"
                  rules="required"
                >
                  <v-select
                    v-model="sectorSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Selecione o Setor"
                    label="title"
                    :state="errors[0] ? 0 : sectorSelected ? null : null"
                    :options="sector_option"
                  />
                  <b-form-valid-feedback>
                    Campo Preenchido!
                  </b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    Por favor Preencha o campo
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-diretor">Gestor: </label>
                <validation-provider
                  name="diretor"
                  rules="required"
                >
                  <v-select
                    v-model="managerSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Selecione o Gestor"
                    label="title"
                    :options="manager_option"
                  />
                  <b-form-valid-feedback>
                    Campo Preenchido!
                  </b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    Por favor Preencha o campo
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-profile">Perfil: </label>
                <v-select
                  v-model="profileSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Selecione o Perfil"
                  :options="profile_option"
                />
                <b-form-valid-feedback>
                  Campo Preenchido!
                </b-form-valid-feedback>
                <b-form-invalid-feedback>
                  Por favor Preencha o campo
                </b-form-invalid-feedback>
              </b-col>

              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-profile">Perfil do Whatsapp: </label>
                <v-select
                  v-model="whatsappProfileSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Selecione o Perfil"
                  :options="whatsappProfilesOptions"
                />
                <b-form-valid-feedback>
                  Campo Preenchido!
                </b-form-valid-feedback>
                <b-form-invalid-feedback>
                  Por favor Preencha o campo
                </b-form-invalid-feedback>
              </b-col>

              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-name">Telefone: </label>
                <validation-provider
                  #default="{ errors }"
                  name="Telefone"
                  rules="required|phone"
                >
                  <b-form-input
                    id="v-name"
                    v-model="phone"
                    v-mask="['(##) #####-####', '(##) ####-####']"
                    placeholder="Digite o telefone do usuário"
                    :state="
                      errors.length > 0 ? false : null
                    "
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-col
              md="12"
              class="mt-1 p-0"
            >
              <b-form-checkbox
                v-model="creditPLatform"
                :value="true"
                :unchecked-value="false"
              >
                Cadastrar na plataforma de crédito
              </b-form-checkbox>
            </b-col>

            <b-col
              v-if="creditPLatform"
              md="12"
              class="mt-1 p-0"
            >
              <b-form-checkbox
                v-model="adminOsirisPermission"
                :value="true"
                :unchecked-value="false"
              >
                O usuário deve ter permissão de administrador
              </b-form-checkbox>
            </b-col>

            <b-row class="mt-1">
              <b-col
                md="2"
                sm="6"
                class="mt-1"
              >
                <b-button
                  variant="primary"
                  block
                  :disabled="isDisabledBtn"
                  @click="validationForm"
                >Salvar</b-button>
              </b-col>
              <b-col
                md="2"
                sm="6"
                class="mt-1"
              >
                <b-button
                  variant="primary"
                  block
                  :to="{ name: 'usuarios' }"
                >Voltar</b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </b-container>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BContainer,
    BIcon,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BOverlay,
    BRow,
    BSpinner,
    BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import { listProfile } from '@/service/profile/profile';
import { createUser } from '@/service/user/userBackOffice';
import { createOsirisUser } from '@/service/credit_platform/users';

import UserAtomService from '@/service/user-atom';

export default {
    components: {
        BOverlay,
        BSpinner,
        BIcon,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BFormInput,
        BCol,
        BRow,
        BCard,
        BContainer,
        BForm,
        BFormValidFeedback,
        BFormInvalidFeedback,
        vSelect,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            adminOsirisPermission: false,
            renderPage: true,
            showLoading: false,
            creditPLatform: false,
            required,
            sectorSelected: null,
            profileSelected: null,
            managerSelected: null,
            whatsappProfileSelected: null,
            whatsappProfiles: [],
            email,
            manager: null,
            profile: null,
            userName: null,
            userEmail: null,
            phone: null,
            sector_option: [
                { title: 'Administração', value: 17 },
                { title: 'Atendimento', value: 5 },
                { title: 'Cadastro', value: 8 },
                { title: 'Cobrança', value: 4 },
                { title: 'Cobrança Soulpay', value: 3 },
                { title: 'Comercial', value: 6 },
                { title: 'Controladoria', value: 11 },
                { title: 'Financeiro', value: 15 },
                { title: 'Financeiro SoulPay', value: 14 },
                { title: 'Jurídico', value: 7 },
                { title: 'Marketing', value: 13 },
                { title: 'Operações', value: 2 },
                { title: 'Operações Soulpay', value: 1 },
                { title: 'Recursos Humanos', value: 12 },
                { title: 'Risco SoulPay', value: 9 },
                { title: 'Risco Via Invest', value: 10 },
                { title: 'TI', value: 16 },
                { title: 'Originação', value: 18 },
                { title: 'Contas a Pagar', value: 19 },
            ],
            manager_option: [
                { title: 'Diogo Favero', value: 3 },
                { title: 'Jaqueline Gomes', value: 5 },
                { title: 'Karen Ferreira', value: 6 },
                { title: 'Luiz Casante', value: 1 },
                { title: 'Natalia Pereira', value: 7 },
                { title: 'Paulo Castro', value: 2 },
                { title: 'Raquel Amaya', value: 4 },
            ],
            profile_option: [],
            whatsappProfilesOptions: [],
        };
    },
    computed: {
        isDisabledBtn() {
            if (this.sectorSelected == null || this.managerSelected == null || this.profileSelected == null) {
                return true;
            }
            return false;
        },
    },
    async created() {
        this.profile_option = await this.listProfile();
        this.whatsappProfilesOptions = await this.listWhatsappProfiles();
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    methods: {
        async listWhatsappProfiles() {
            const { data: { data } } = await UserAtomService.index();

            const optionsProfile = data.map(profile => {
                const { name, id } = profile;

                return {
                    title: name,
                    value: id,
                };
            });

            return optionsProfile.sort((a, b) => (a.title.localeCompare(b.title)));
        },
        async listProfile() {
            const { data } = await listProfile();
            const { profiles } = data;

            const optionsProfile = profiles.map(profile => {
                const { profile_name, id } = profile;

                return {
                    title: profile_name,
                    value: id,
                };
            });

            return optionsProfile.sort((a, b) => (a.title.localeCompare(b.title)));
        },
        async registerUser() {
            this.showLoading = true;
            const user = {
                name: this.userName,
                email: this.userEmail,
                sector_id: this.sectorSelected.value,
                manager_id: this.managerSelected.value,
                profile_id: this.profileSelected.value,
                whatsapp_group_profile_id: this.whatsappProfileSelected.value,
                phone: `55${this.phone.replace(/[^\w\s]/g, '').replace(' ', '')}`,
            };
            const { status, data } = await createUser(user);
            if (this.creditPLatform && status === 200) {
                const { status: statusOsiris } = await createOsirisUser({
                    user_id: data.user_id,
                    full_access: this.adminOsirisPermission,
                });

                if (statusOsiris === 401 || statusOsiris === 502) {
                    this.error('Usuário cadastrado no portal, mas você não tem permissão para cadastrar o usuário na plataforma de crédito');
                    this.showLoading = false;
                    return;
                }
            }

            if (status === 200) {
                this.clear();
                this.$nextTick(() => this.$refs.simpleRules.reset());
                this.success();
            } else {
                this.error(`Não foi possível Cadastrar o usuário ${this.userEmail}`);
            }
            this.showLoading = false;
        },
        clear() {
            this.userEmail = null;
            this.userName = null;
            this.sectorSelected = null;
            this.managerSelected = null;
            this.profileSelected = null;
            this.creditPLatform = false;
        },
        error(msg) {
            this.$swal({
                icon: 'error',

                html: `<span> ${msg} </span> `,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>Usuário criado com sucesso</span> ',
            });
        },
        async validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.showConfirm();
                }
            });
        },
        showConfirm() {
            this.$bvModal
                .msgBoxConfirm(`Deseja Cadastrar esse usuário, ${this.userEmail}`, {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.registerUser();
                    }
                });
        },

        methodThatForcesUpdate() {
            // ...
            this.$forceUpdate(); // Notice we have to use a $ here
            // ...
        },
    },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/pages/new-client.scss";

</style>
