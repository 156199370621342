<template>
  <b-container>
    <b-card title="Buscar Usuário">
      <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-input
          v-model="filter"
          placeholder="Informe o Nome do Funcionário"
          type="text"
          class="d-inline-block mr-1"
        />

        <b-button
          variant="primary"
          :to="{ name: 'novo usuario' }"
        >
          <span class="text-nowrap">Novo Usuário</span>
        </b-button>
      </b-col>
    </b-card>
    <b-card>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="table_users_fields"
        :items="users"
        :filter="filter"
        striped
        hover
        responsive
        :busy="isLoading"
        class="text-center"
        :tbody-tr-class="rowRevoked"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'edit-user', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.isRevoked !== 1"
              @click="modalRevoked(data)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">Revogar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="!isLoading"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
  </b-container>
</template>
<script>
import {
    BContainer,
    BTable,
    BButton,
    BPagination,
    BCard,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
} from 'bootstrap-vue';
import { listAllUsers, revokeUser } from '@/service/user/userBackOffice';

export default {
    components: {
        BContainer,
        BCol,
        BTable,
        BButton,
        BPagination,
        BCard,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BSpinner,
    },
    data() {
        return {
            filter: null,
            perPage: 8,
            totalRows: null,
            currentPage: 1,
            isLoading: false,
            detailsClassFn: 'is-revoked',
            table_users_fields: [
                { key: 'name', label: 'Nome' },
                { key: 'email', label: 'E-mail' },
                { key: 'sector', label: 'Setor' },
                { key: 'manager', label: 'Gestor' },
                { key: 'profile', label: 'Perfil' },
                { key: 'actions', label: 'Ações' },
            ],
            users: null,
        };
    },
    async mounted() {
        await this.listAllUsers();
    },
    methods: {
        rowRevoked(item) {
            return item?.isRevoked === 1 ? 'is-revoked' : '';
        },
        async revokeUser(userName, id) {
            this.isLoading = true;
            const response = await revokeUser(id, {
                is_revoked: true,
            });
            this.responseModal(response === 200, userName);

            if (response === 200) {
                const indexUser = this.users.findIndex(user => user.idRevoke === id);
                this.users[indexUser].isRevoked = 1;
            }
            this.isLoading = false;
        },
        responseModal(success, user) {
            if (success) {
                this.$swal({
                    icon: 'success',
                    html: `<span>O usuário ${user} foi revogado com sucesso </span>`,
                });
            } else {
                this.$swal({
                    icon: 'error',
                    html: `<span> Não foi possível revogar o usuário ${user} </span>`,
                });
            }
        },
        modalRevoked({ item }) {
            this.$bvModal
                .msgBoxConfirm(`Deseja realmente revogar o usuário ${item.name}?`, {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) { this.revokeUser(item.name, item.idRevoke); }
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async listAllUsers() {
            this.isLoading = true;

            const { data } = await listAllUsers();
            const { users } = data;

            this.totalRows = users.length;

            const usersList = users.map(user => ({
                idRevoke: user.user_id,
                id: user.id,
                email: user.user.email,
                name: user.user.name,
                sector: user.backoffice_sector.name,
                manager: user.backoffice_manager.name,
                profile: user.backoffice_profile.profile_name,
                isRevoked: user.user.is_revoked,
            }));

            this.users = usersList.sort((a, b) => (a.name.localeCompare(b.name)));
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
  .is-revoked {
    background-color: rgba(0, 0, 0 , .09) !important;
    &:hover{
      background-color: rgba(0, 0, 0 , .11) !important;
    }
  }
</style>
