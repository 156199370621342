<template>
  <b-container>
    <b-card title="Buscar Perfil">
      <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-input
          v-model="filter_profile"
          placeholder="Informe o Nome do Perfil"
          type="text"
          class="d-inline-block mr-1"
        />

        <b-button
          variant="primary"
          :to="{ name: 'new-whatsapp-profile' }"
        >
          <span class="text-nowrap">Novo Perfil</span>
        </b-button>
      </b-col>
    </b-card>
    <b-card>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="table_profile_fields"
        :items="table_profile_items"
        :filter="filter_profile"
        :busy="isLoading"
        striped
        hover
        responsive
        class="text-center"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'edit-whatsapp-profile', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="!isLoading"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
  </b-container>
</template>
<script>
import {
    BContainer,
    BTable,
    BButton,
    BPagination,
    BCard,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
} from 'bootstrap-vue';

import UserAtomService from '@/service/user-atom';

export default {
    components: {
        BContainer,
        BCol,
        BTable,
        BButton,
        BPagination,
        BCard,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BSpinner,
    },
    data() {
        return {
            filter_profile: null,
            perPage: 8,
            totalRows: null,
            currentPage: 1,
            filter_profile_on: [],
            isLoading: false,
            table_profile_fields: [
                { key: 'name', label: 'Nome' },
                { key: 'actions', label: 'Ações' },
            ],
            table_profile_items: null,
        };
    },
    async mounted() {
        await this.listAllProfile();
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async listAllProfile() {
            this.isLoading = true;

            const { data, status } = await UserAtomService.index();

            if (status === 200) {
                const profiles = data.data;
                this.totalRows = profiles.length;

                const profileList = profiles.map(profile => {
                    const {
                        id, name,
                    } = profile;

                    return {
                        id,
                        name,
                    };
                });

                this.table_profile_items = profileList.sort((a, b) => (a.name.localeCompare(b.name)));
                this.isLoading = false;
            }
        },
    },
};
</script>
  <style lang="scss" scoped>
  </style>
