<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="show_loading"
      rounded="lg"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>{{ msg }}, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-col
        class="mr-auto ml-auto"
        xl="6"
        md="12"
        sm="12"
        lg="6"
      >
        <b-card title="Criar Novo Perfil">
          <validation-observer ref="simpleRules">
            <b-form
              novalidate
              class="needs-validation"
              @submit.prevent
            >
              <b-row>
                <b-col
                  sm="12"
                  lg="12"
                  md="12"
                  class="mb-1"
                >
                  <label for="v-nome-perfil">Nome do Perfil:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="profile"
                    rules="required"
                  >
                    <b-form-input
                      id="v-nome-perfil"
                      v-model="profileName"
                      placeholder="Informe o nome do Perfil"
                      :state="
                        errors.length > 0 ? false : profileName ? true : null
                      "
                    />
                    <b-form-valid-feedback>
                      Campo Preenchido!
                    </b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      Por favor Preencha o campo
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  lg="12"
                  md="12"
                >

                  <b-form-group label="Escolha os Itens do Menu">
                    <div
                      v-for="(item, key, index) in options"
                      :key="index"
                      class="mb-1"
                    >
                      <permission
                        :options="[...item]"
                        :item="key"
                        :permissions="permissions"
                        :new="true"
                        @update="updatePermission"
                      />
                    </div>
                  </b-form-group>
                </b-col></b-row>
              <b-row>
                <b-col md="6">
                  <b-button
                    class="mt-1"
                    variant="primary"
                    block
                    @click="validationForm"
                  >Cadastrar</b-button>
                </b-col>
                <b-col md="6">
                  <b-button
                    class="mt-1"
                    variant="primary"
                    block
                    :to="{ name: 'usuarios' }"
                  >Voltar</b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import {
    BButton,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BContainer,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BSpinner,
    BOverlay,
    BIcon,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import { createProfile } from '@/service/profile/profile';

import PermissionService from '@/service/permission';

import Permission from './components/Permissions.vue';

export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        BCard,
        BContainer,
        BForm,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BSpinner,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
        BIcon,
        Permission,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            renderPage: true,
            profileName: null,
            required,
            email,
            permissions: [],
            selected_permission: [],
            selectedPermissionDashboard: [],
            show_loading: false,
            msg: ' ',
            options: {},
        };
    },

    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },

    mounted() {
        this.getPermissions();
    },

    methods: {
        async getPermissions() {
            const { data: { data } } = await PermissionService.index();

            this.options = (data.sort((a, b) => {
                if (a.permission_group && b.permission_group) {
                    return a.permission_group.description.localeCompare(b.permission_group.description);
                } if (a.permission_group && !b.permission_group) {
                    return 0;
                } if (b.permission_group && !a.permission_group) {
                    return 1;
                }

                return 1;
            }).reduce((acc, item) => {
                if (!item.permission_in_portuguese) return acc;
                if (!item.permission_group) {
                    acc[item.permission_in_portuguese] = item;
                } else {
                    if (!acc[item.permission_group.description]) acc[item.permission_group.description] = [];
                    acc[item.permission_group.description].push(item);
                }
                return acc;
            }, {}));
        },
        selectedAll() {
            if (this.allSelectedDashboard) {
                this.selectedPermissionDashboard = this.dashboardPermissions.map(option => option.value);
            } else {
                this.selectedPermissionDashboard = [];
            }
        },
        async createProfile() {
            this.msg = 'Criando novo Perfil';
            this.show_loading = true;

            const { profileName, permissions } = this;

            const { status } = await createProfile({
                profileName,
                profilePermissions: permissions,
            });

            if (status === 200) {
                this.show_loading = false;
                this.success();
            } else {
                this.show_loading = false;
                this.error(`Não foi Cadastrar o perfil ${profileName}!`);
            }
        },
        async validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.createProfile();
                }
            });
        },
        success() {
            this.$swal({
                icon: 'success',

                html: '<span>Perfil Cadastrado com Sucesso! </span> ',
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },
        error(msg) {
            this.$swal({
                icon: 'error',

                html: `<span> ${msg} </span> `,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },

        updatePermission({ all, calculated }) {
            const { permissions } = this;
            const valoresPresentes = permissions.filter(valor => !all.includes(valor));

            calculated.forEach(item => valoresPresentes.push(item));

            this.permissions = valoresPresentes;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/new-client.scss";
</style>
