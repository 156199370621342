import methods from '@/enums/methods';
import Ramses from '../base/ramses';

class UserAtomService extends Ramses {
    async index() {
        return this.fetch({
            url: '/atom/profile',
        });
    }

    async showGroups() {
        return this.fetch({
            url: '/atom/groups',
        });
    }

    async showProfile({ id }) {
        return this.fetch({
            url: `/atom/profile/${id}`,
        });
    }

    async createProfile({
        name,
        groupIds,
    }) {
        return this.fetch({
            url: '/atom/profile',
            method: methods.POST,
            data: {
                name,
                group_ids: groupIds,
            },
        });
    }

    async updateProfile({
        id,
        name,
        groupIds,
    }) {
        return this.fetch({
            url: '/atom/profile',
            method: methods.PUT,
            data: {
                profile_id: id,
                name,
                group_ids: groupIds,
            },
        });
    }
}

export default new UserAtomService();
